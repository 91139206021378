import { Tooltip } from 'components/ui'
import React from 'react'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { VscError } from 'react-icons/vsc'
import useThemeClass from 'utils/hooks/useThemeClass'

const actionStatus = {
    2: {
        label: 'Paid',
        icon: <FaArrowUp className="w-5 h-5" />,
        textClass: 'text-emerald-500',
    },
    1: {
        label: 'Pending',
        icon: <FaArrowDown className="w-5 h-5" />,
        textClass: 'text-amber-500',
    },
    0: {
        label: 'Failed',
        icon: <VscError className="w-5 h-5" />,
        textClass: ' text-red-500',
    },
}
export const returnErrorText = (visitStatus) => {
    switch (visitStatus) {
        case 'First_Visit':
            return ''
        case 'access':
            return ''
        case 'No_Sale_yet':
            return 'გაყიდვა არ ფიქსირდება'
        case 'Expired_Sale':
            return 'ვადაგასული'
        case 'Over_Limit':
            return 'განმეორებით შესვლა'
        case 'Already_Used_Daily_Limit':
            return 'დღიური ლიმიტი ამოიწურა'
        case 'Today_Is_Your_Last_Visit':
            return 'აბონიმენტის ბოლო ვიზიტი'
        case 'Fix_Double_Entry':
            return 'ხშირი გამოყენება'
        case 'Card_Successfully_Activated':
            return 'ბარათი წარმატებით გააქტიურდა'
        case 'Weekly_Limit':
            return 'კვირის დღის შეზღუდვა'
        case 'You_Have_Morning_Visits':
            return 'დღის მონაკვეთის შეზღუდვა'
        case 'Hourly_Limit':
            return 'დღის საათობრივი შეზღუდვა'
        case 'Unknow':
            return 'არააქტიური ბარათი'

        default:
            break
    }
}

const CustomEntranceLog = ({ status, visitStatus }) => {
    const { textTheme } = useThemeClass()
    if (status == 1) {
        return <FaArrowDown className="w-5 h-5 text-amber-500" />
    } else if (status == 2) {
        if (visitStatus === 'First_Visit' || visitStatus === 'access') {
            return <FaArrowUp className="w-5 h-5 text-emerald-500" />
        } else if (
            visitStatus === 'Over_Limit' ||
            visitStatus === 'Today_Is_Your_Last_Visit' ||
            visitStatus === 'Card_Successfully_Activated'
        ) {
            return (
                <Tooltip
                    className="p-0"
                    title={
                        <strong className={`${textTheme}`}>
                            {returnErrorText(visitStatus)}
                        </strong>
                    }
                >
                    <FaArrowUp className="w-5 h-5 text-emerald-500" />
                </Tooltip>
            )
        } else {
            return (
                <Tooltip
                    className="p-0"
                    title={
                        <strong className={`${textTheme}`}>
                            {returnErrorText(visitStatus)}
                        </strong>
                    }
                >
                    <VscError className="w-5 h-5 text-red-500" />
                </Tooltip>
            )
        }
    }
}

export default CustomEntranceLog
