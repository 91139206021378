import React, { memo, useMemo, lazy, Suspense, useEffect } from 'react'
import { Loading } from 'components/shared'
import { useDispatch, useSelector } from 'react-redux'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant'
import useAuth from 'utils/hooks/useAuth'
import useDirection from 'utils/hooks/useDirection'
import useLocale from 'utils/hooks/useLocale'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { VscError } from 'react-icons/vsc'
import { io } from 'socket.io-client'
import { Notification, toast } from 'components/ui'
import { setCustomerData } from 'store/socket/socketSlice'
import { returnErrorText } from 'utils/customComponents/CustomEntranceLog'
import appConfig from 'configs/app.config'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}
const { socketPrefix } = appConfig
const socket = io(socketPrefix)
// const { apiPrefix } = appConfig
// const socket = io(apiPrefix)

const socketNotificationHeaderMessage = (socketData) => {
    if (socketData.event == 1) {
        return (
            <Notification
                duration={4000}
                title={
                    <div className="flex justify-between items-center">
                        <span className="text-amber-500 mr-4">
                            <FaArrowDown className="w-5 h-5" />
                        </span>
                        <span>{`${socketData.firstname} ${socketData.lastname}`}</span>
                    </div>
                }
            />
        )
    } else if (socketData.event == 2) {
        if (
            socketData?.visitStatus === 'First_Visit' ||
            socketData?.visitStatus === 'access' ||
            socketData?.visitStatus === 'Over_Limit' ||
            socketData?.visitStatus === 'Today_Is_Your_Last_Visit' ||
            socketData?.visitStatus === 'Card_Successfully_Activated'
        ) {
            return (
                <Notification
                    duration={4000}
                    title={
                        <div className="flex justify-between items-center">
                            <FaArrowUp className="w-5 h-5 text-emerald-500 mr-4" />
                            <div className="flex flex-col justify-start justify-items-start">
                                <span>{`${socketData.firstname} ${socketData.lastname}`}</span>
                                <span>
                                    {returnErrorText(socketData?.visitStatus)}
                                </span>
                            </div>
                        </div>
                    }
                />
            )
        } else {
            return (
                <Notification
                    duration={4000}
                    title={
                        <div className="flex justify-between items-center">
                            <VscError className="w-5 h-5 text-red-500 mr-4" />
                            <div className="flex flex-col justify-start justify-items-start">
                                <span>{`${socketData.firstname} ${socketData.lastname}`}</span>
                                <span>
                                    {returnErrorText(socketData?.visitStatus)}
                                </span>
                            </div>
                        </div>
                    }
                />
            )
        }
    }
}

const Layout = () => {
    const layoutType = useSelector((state) => state.theme.layout.type)
    const dispatch = useDispatch()
    const { authenticated } = useAuth()
    const authority = useSelector((state) => state.auth.user.authority)
    useDirection()
    useLocale()

    useEffect(() => {
        /* socket.connect() */
        if (authority.includes('admin') || authority.includes('basic')) {
            socket.on('connect', () => {
                toast.push(
                    <Notification
                        title={'სერვერთან კავშირი'}
                        type={'success'}
                        closable
                        duration={4000}
                    />,
                    { placement: 'top-center' }
                )
            })
            socket.on('customerData', (data) => {
                toast.push(socketNotificationHeaderMessage(data))
                dispatch(setCustomerData(data))
                console.log('socket customerData => ', data) // true
            })

            return () => {
                toast.push(
                    <Notification
                        title={'სერვერთან კავშირი გაწყდა'}
                        type={'danger'}
                        closable
                        duration={4000}
                    />,
                    { placement: 'top-center' }
                )
            }
        }
    }, [authority, dispatch])

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default memo(Layout)
