import { createSlice } from '@reduxjs/toolkit'

export const socketSlice = createSlice({
    name: 'socket/socketSession',
    initialState: {
        socket: false,
        customerData: {},
    },
    reducers: {
        setSocket(state, action) {
            state.socket = action.payload
        },
        setCustomerData(state, action) {
            state.customerData = action.payload
        },
        clearCustomerData(state) {
            state.customerData = {}
        },
    },
})

export const { setSocket, setCustomerData, clearCustomerData } =
    socketSlice.actions

export default socketSlice.reducer
