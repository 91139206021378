// const appConfig = {
//     apiPrefix: 'http://localhost:5000',
//     authenticatedEntryPath: '/app/home/dashboard',
//     unAuthenticatedEntryPath: '/sign-in',
//     tourPath: '/app/account/kyc-form',
//     locale: 'en',
//     enableMock: false,
// }

// export default appConfig

const appConfig = {
    // apiPrefix: 'http://localhost:5000',
    apiPrefix: '/api',
    // socketPrefix: 'http://localhost:5000',
    socketPrefix: 'http://207.154.220.163',
    /* authenticatedEntryPath: '/app/crm/customers', */
    authenticatedEntryPath: '/app/home/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}
export default appConfig
